import { Banner } from "components/common/composite";
import { useTranslation } from "translations";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  const isOutdated = typeof Promise.withResolvers === "undefined";

  return (
    <div>
      {isOutdated && (
        <Banner
          borderless
          variant="warning"
          title={t(
            "bannerSection.outdated.title",
            "Looks like you are using an outdated browser"
          )}
          body={t(
            "bannerSection.outdated.body",
            "Manymore functions best on the newest browser versions, and we recommend that you update your browser."
          )}
        />
      )}
    </div>
  );
};
